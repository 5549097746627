
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import SchoolMixin from "@/mixins/school.mixin";
import {
  AcademicOffering,
  academicOfferingsArray,
  BaseModalType,
} from "@/models/schoolfinder.enums";
@Component({
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
})
export default class SchoolAbout extends Mixins(SchoolMixin) {
  showItemDetail = false;
  mobileView!: boolean;
  tabletView!: boolean;
  dataList = [];
  selectedHeaderText = "";
  academicOfferingsArray = academicOfferingsArray;
  academicOffering = AcademicOffering;

  BaseModalType = BaseModalType;

  showModal(academicOffering: string) {
    this.selectedHeaderText = academicOffering;

    switch (academicOffering) {
      case academicOfferingsArray[AcademicOffering.AdvancedPlacement].name:
        this.dataList = this.schoolAbout.advancedPlacementList;
        break;
      case academicOfferingsArray[AcademicOffering.WorldLanguageImmersion].name:
        this.dataList = this.schoolAbout.wliList;
        break;
      case academicOfferingsArray[AcademicOffering.SealOfBiliteracy].name:
        this.dataList = this.schoolAbout.sealOfBiliteracyList;
        break;
      case academicOfferingsArray[AcademicOffering.DualEnrollment].name:
        this.dataList = this.schoolAbout.dualEnrollmentList;
        break;
      case academicOfferingsArray[AcademicOffering.JumpStartPathways].name:
        this.dataList = this.schoolAbout.jumpStartList;
        break;
      case academicOfferingsArray[AcademicOffering.ForeignLanguage].name:
        this.dataList = this.schoolAbout.foreignLangOfferedList;
        break;
      default:
        this.dataList = [];
        break;
    }

    this.showItemDetail = true;
  }

  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  get overview() {
    if (!this.schoolAbout) return [];

    const items = [
      {
        header: "Overview",
        dataTiles: [
          {
            id: 1,
            label: "Ages / Grades Served",
            value: this.gradesServed,
            valueSecond: this.gradesServedCurrent,
          },
          {
            id: 2,
            label: "Director / Principal",
            value: this.schoolAbout.principalName,
          },
          {
            id: 3,
            label: "District / Parish",
            value: this.schoolAbout.districtName,
          },
          {
            id: 4,
            label: "Charter Management Organization",
            value: this.schoolAbout.cmo,
          },
          {
            id: 5,
            label: "School Type",
            value: this.schoolAbout.schooltype,
          },
          {
            id: 6,
            label: "How to Enroll",
            link: this.schoolAbout.enrollmentLink,
          },
        ],
      },
      {
        header: "Additional Information",
        dataTiles: [
          {
            id: 1,
            label: "Total Student Enrollment",
            value: this.schoolAbout.totalEnrollmentFormatted,
          },
          {
            id: 2,
            label: "Student to Device Ratio",
            value: this.schoolAbout.technologyfocusFormatted,
          },
        ],
      },
    ];
    return items.map((item) => {
      return {
        header: item.header,
        dataTiles: item.dataTiles.filter((y) => Boolean(y.value)),
      };
    });
  }

  get additionalSections() {
    if (!this.schoolAbout) return [];

    return [
      {
        id: 1,
        secondId: 1.1,
        header: "Music / Art",
        data: this.schoolAbout.musicArtList,
      },
      {
        id: 2,
        secondId: 2.1,
        header: "After-School Opportunities and Clubs",
        data: this.schoolAbout.clubsList,
      },
      {
        id: 3,
        secondId: 3.1,
        header: "Sports",
        data: this.schoolAbout.sportsList,
      },
    ];
  }

  get schoolAbout() {
    return this.school && this.school.schoolAbout;
  }

  get compareGrades() {
    return (
      this.schoolAbout &&
      this.schoolAbout.gradeservedcurrentsy ===
        this.schoolAbout.gradeservedprevioussy
    );
  }

  get gradesServed() {
    let result;
    if (this.compareGrades) result = this.schoolAbout.gradeservedprevioussy;
    else
      result =
        this.schoolAbout.gradeservedprevioussy.trim() != ""
          ? this.schoolAbout.gradeservedprevioussy +
            " (" +
            this.schoolAbout.previousSchoolYear +
            ")"
          : " ";
    return result;
  }

  get gradesServedCurrent() {
    let result;
    if (this.compareGrades) result = "";
    else
      result =
        this.schoolAbout.gradeservedcurrentsy +
        " (" +
        this.schoolAbout.currentSchoolYear +
        ")";
    return result;
  }
}
